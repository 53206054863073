@font-face {
  font-family: 'Axis';
  font-style: normal;
  font-weight: normal;
  src: local('Axis'), url('fonts/axis.woff') format('woff');
}
@font-face {
  font-family: 'Axis';
  src: url('fonts/AXIS-ExtraBold.woff2') format('woff2'),
  url('fonts/AXIS-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
html,
body,
p {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  background: linear-gradient(#5f5a8b, #492831);
  font-family: 'Axis', sans-serif;
}

html, body, #root, .App, .wrapper-container, .items-container, .comparable-item {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
}

.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.wrapper-container {
  height: 100%;
}

.items-container {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}

.items-container .comparable-item {
  height: 100%;
  background-color: #939598;
  position: relative;
  background-size: cover;
}

.comparable-item .header {
  padding-top: 30px;
  font-weight: bold;
}

.comparable-item .title {
  font-size: 2rem;
  line-height: 0.5rem;
  text-align: right;
  color: black;
}

.comparable-item .subtitle {
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: right;
  color: black;
}

.items-container .comparable-item img {
  width: 280px;
  max-width: 100%;
}

.comparable-item .body {
  padding-top: 20px;
  padding-left: 30px;
}

.comparable-item .body ul {
  padding: 0;
  margin: 0;
}

.comparable-item .body li {
  list-style: none;
}

.comparable-item .body {
  font-size: 14px;
  color: white;
  text-align: left;
}

.comparable-item .body li {
  margin-bottom: 14px;
}

.comparable-item .body p {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 0;
}

.comparable-item .body .colors {
  padding-bottom: 8px;
}

.comparable-item .body .colors span {
  display: inline-block;
  border-radius: 100%;
  border: 1px solid white;
  width: 15px;
  height: 15px;
}

.comparable-item .footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  color: white;
  background: rgb(31, 31, 29);
  background: linear-gradient(90deg, rgba(31, 31, 29, 1) 0%, rgba(39, 38, 36, 1) 100%);
  padding: 10px 0;
}

.comparable-item .footer {
  text-align: center;
}
.comparable-item .footer p {
  text-transform: uppercase;
}

.items-1 .comparable-item .footer-container {
  background: transparent !important;
}

.comparable-item .footer p {
  margin: 0;
  font-size: 50px;
  line-height: 50px;
}

.items-1 .header {
  padding-top: 80px;
}

.items-1 .comparable-item .footer p {
  font-size: 150px;
  line-height: 150px;
}

.items-1 .title {
  line-height: 2.5rem;
  color: white;
}

.items-1 .subtitle {
  color: white;
  font-size: 3rem;
  line-height: 1rem;
  margin-bottom: 0;
}

.items-1 .content {
  display: flex;
}

.items-1 .content .image {
  width: 70%;
  padding: 40px 20px;
  max-height: 520px;
}

.items-1 .content .body {
  width: 30%;
}

.items-1 .footer {
  padding-bottom: 40px;
}

.items-1 .comparable-item img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.items-2 .header, .items-3 .header {
  padding-top: 50px;
}

.items-2 .content .body, .items-3 .content .body {
  margin-top: 40px;
  padding: 0 40px;
}

.items-2 .content .body ul, .items-3 .content .body ul {
  width: 50%;
  float: left;
}

.items-2 .comparable-item .body span {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 14px;
}

.items-2 .comparable-item .body p {
  font-size: 44px;
  line-height: 44px;
  margin-bottom: 0;
}

.items-3 .comparable-item .body span {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 14px;
}

.items-3 .comparable-item .body p {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 0;
}

.scan-message {
  position: absolute;
  top: 20px;
  right: 20px;
}
#debug-output {
  position: fixed;
  width: 100%;
  height: 300px;
  background-color: grey;
}
